<template>
  <div :style="slidePosition" class="w-100 z-5 h-100 ">
    <div class="position-absolute p-50-50 h-100 background" style="width:100%"></div>
    <div class="position-absolute red-giant" style="width:40%;left:30%;top:30%;transform:translate(-50%,-50%)">
      <img src="images/Slide14/1992-stellar-origin-RedGiant-TRIUMF.png" style="width:100%" />
    </div>
    <div iv class="position-absolute text-white " style="width:45%;left:55%;top:50%;transform: translate(-50%,-50%)">
      <h3 class="font-vw-3-5 font-w-600 mb-0 fade8" style="opacity:0;">
        1992
      </h3>
      <h6 class="font-vw-2-5 font-w-600 fade8 my-0" style="opacity:0; ">
        STELLAR ORIGINS
      </h6>
      <p class="font-vw-1-35 text-8 mt-3" style="opacity:0; ">
        TRIUMF’s Test Isotope Separator On–Line (TISOL) facility gave
        researchers the ability to reproduce, here on Earth, key
        element-building reactions in stars. The Red Giant experiment, named
        after Sun-like stars at the end of their lives, studied the production
        rate of oxygen-16 in these dying stars. TRIUMF is now a world-leading
        facility for laboratory-based nuclear astrophysics experiments.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    this.startLoop()
  },
  methods: {
    slide7(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fade8",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".text-8",

          translateY: ["35%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    redGiant(percentage) {
      let animation = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 500
        })
        .add({
          targets: ".red-giant",
          scale: [0, 1],
          easing: "easeInOutSine",
          duration: 500,
          delay: (el, i) => 1500 + i * 250
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    startLoop() {
      anime({
        targets: ".particle",
        width: ["32%", "35%"],
        direction: "alternate",
        loop: true,
        duration: 1200,
        easing: "easeInOutSine"
      })
    }
  },
  watch: {
    innerPosition() {
      this.redGiant(this.innerPosition)

      this.slide7(this.innerPosition)
    }
  }
}
</script>

<style lang="scss" scoped>
.background {
  background-color: #0c295b;
  background: linear-gradient(180deg,
      #0c295b 15%,
      rgba(12, 41, 91, 0.75) 45%,
      transparent 100%);
}
</style>
